import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

/**
 * Provides a bundle of convenience functions for react-router
 * @see https://usehooks.com/useRouter/
 */
export function useRouter() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => qs.parse(location.search), [location]);

  // TODO: Replace all instances of setUrlParam
  const setSearchParams = (updatedParams) => {
    const newSearchParams = {
      ...searchParams,
      ...updatedParams,
    };
    navigate(`?${qs.stringify(newSearchParams)}`);
  };

  return {
    navigate,
    location,
    searchParams,
    setSearchParams,
  };
}
