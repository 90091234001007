import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { HiInformationCircle } from 'react-icons/hi';
import PropTypes from 'prop-types';

import { classNames } from 'utils/format';

export function Tooltip({ text, position = 'top', children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const tooltipClasses = classNames(
    'inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-all duration-200 rounded-full hover:text-gray-500',
    {
      'cursor-pointer': !!text,
    }
  );

  const tooltipTextClasses = classNames(
    'absolute z-tooltip w-48 px-3 py-2 text-sm text-white bg-black rounded-md shadow',
    {
      'opacity-100 scale-100': isOpen,
      'opacity-0 scale-95': !isOpen,
      'origin-bottom': position === 'top',
      'origin-top': position === 'bottom',
      'origin-left': position === 'right',
      'origin-right': position === 'left',
    }
  );

  return (
    <div className="relative">
      <div
        className={tooltipClasses}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <HiInformationCircle />
      </div>

      {text && (
        <Transition
          show={isOpen}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tooltipTextClasses}>{text}</div>
        </Transition>
      )}

      {children}
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  text: '',
  position: 'top',
};
