/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import { classNames } from 'utils/format';

export default function FormInput({ label, name, options, ...props }) {
  const { errors, touched } = useFormikContext();
  return (
    <div className="floating-input relative text-white">
      <Field
        as="select"
        id={name}
        name={name}
        className={classNames(
          errors[name] && touched[name]
            ? 'border-[#CF6679]'
            : 'border-white border-opacity-[0.12]',
          'w-full rounded-md border bg-transparent p-3 focus:border-gray-500 focus:shadow-sm focus:outline-none'
        )}
        {...props}
      >
        <option
          value=""
          disabled
          className="relative cursor-default select-none py-2 pl-10 pr-4 text-dark"
        >
          Choose an option
        </option>
        {options.map(({ label, value }) => (
          <option
            key={value}
            value={value}
            className="relative cursor-default select-none py-2 pl-10 pr-4 text-dark"
          >
            {label}
          </option>
        ))}
      </Field>
      {label && (
        <label
          htmlFor={name}
          className="pointer-events-none absolute top-0 left-0 h-full origin-left transform px-3 py-5 transition-all duration-200 ease-in-out"
        >
          {label}
        </label>
      )}
      {errors[name] && touched[name] && (
        <p className="mt-2 ml-2 text-sm text-[#e65e77]" id={`${name}-error`}>
          {errors[name]}
        </p>
      )}
    </div>
  );
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
