import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

export default function FormCheckboxGroup({
  label,
  name,
  options,
  maxSelected,
}) {
  const { values } = useFormikContext();

  function isDisabled(value) {
    return !values[name].includes(value) && values[name].length >= maxSelected;
  }

  return (
    <div className="w-full rounded-md border border-white border-opacity-[0.12] px-3 pt-2 pb-3">
      <label htmlFor={name} className="text-sm text-white text-opacity-75">
        {label}
      </label>
      <ErrorMessage name={name}>
        {(error) => (
          <p className="mt-2 mb-4 text-sm text-[#e65e77]" id={`${name}-error`}>
            {error}
          </p>
        )}
      </ErrorMessage>
      <div
        role="group"
        aria-labelledby={name}
        className="my-2 flex flex-wrap gap-y-3 gap-x-1"
      >
        {options.map(({ label, value }) => (
          <div key={value} className="button-checkbox-control">
            <Field
              type="checkbox"
              id={value}
              name={name}
              className="button-checkbox"
              value={value}
              disabled={isDisabled(value)}
            />
            <label className="button-checkbox-label" htmlFor={value}>
              {label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

FormCheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  maxSelected: PropTypes.number,
};

FormCheckboxGroup.defaultProps = { maxSelected: 99 };
