/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

import { StarRating } from 'components/StarRating';

export default function FormStarRating({ label, name, ...props }) {
  return (
    <div className="relative flex flex-col gap-y-2">
      <div className="flex items-center">
        <label htmlFor={name}>{label}</label>
      </div>
      <Field type="number" id={name} name={name} {...props}>
        {({ field: { value }, form: { setFieldValue } }) => (
          <>
            <StarRating
              height={6}
              width={6}
              value={value}
              onChange={(number) => setFieldValue('rating', number)}
            />
            <small className="sr-only">({value} Star(s))</small>
          </>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(error) => (
          <p className="mt-2 ml-2 text-sm text-[#e65e77]" id={`${name}-error`}>
            {error}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

FormStarRating.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
