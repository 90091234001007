import { Fragment, useRef } from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog, Transition } from '@headlessui/react';

const noOp = () => {};

export default NiceModal.create(
  ({
    title,
    message,
    confirmButtonLabel,
    beforeCancel = noOp,
    beforeConfirm = noOp,
  }) => {
    const modal = useModal();
    const cancelButtonRef = useRef();

    const handleConfirm = () => {
      beforeConfirm();
      modal.resolve();
      modal.hide();
    };

    const handleCancel = () => {
      beforeCancel();
      modal.hide();
    };

    return (
      <Transition appear show={modal.visible} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={cancelButtonRef}
          className="relative z-modal"
          onClose={() => modal.hide()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-cloud bg-opacity-10" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="-mt-4 flex min-h-full items-end justify-center p-4 text-center sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 -translate-y-5"
                enterTo="opacity-100 transform-none"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-none"
                leaveTo="opacity-0 -translate-y-5"
              >
                <Dialog.Panel className="relative w-full max-w-lg transform overflow-hidden rounded-xl bg-[#19191C] py-6 px-5 text-left font-dm-sans text-cloud antialiased shadow-xl transition-all sm:my-8 sm:max-w-xl">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#CF6679] sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    </div>

                    <div className="mt-3 space-y-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="font-bold leading-6 sm:text-lg"
                      >
                        {title}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-sm text-cloud text-opacity-80 sm:mt-0 sm:text-base">
                        {message}
                      </Dialog.Description>
                    </div>
                  </div>

                  <div className="mt-5 bg-[#19191C] sm:mt-6 sm:flex sm:w-auto sm:flex-row-reverse">
                    <button
                      type="button"
                      className="btn btn-block bg-[#CF6679] text-cloud  hover:bg-[#974453] sm:ml-3 sm:w-auto"
                      onClick={handleConfirm}
                    >
                      {confirmButtonLabel}
                    </button>
                    <button
                      type="button"
                      ref={cancelButtonRef}
                      onClick={handleCancel}
                      className="btn btn-outline btn-block mt-4 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);
