import PropTypes from 'prop-types';
import { StarIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';

export function ReviewCard({ review }) {
  return (
    <li>
      <div className="rounded-lg border border-white border-opacity-[0.12] shadow">
        <div className="space-y-2 p-4 md:p-6">
          {/* Title */}
          <h3 className="text-sm font-medium text-cloud sm:text-base">
            {review.title}
          </h3>
          {/* Date & Service */}
          <p className="text-sm text-darkText sm:text-base">
            <span>{review.service}</span> &#8226;{' '}
            <span>
              Review left on{' '}
              <time dateTime={format(new Date(review.date), 'yyyy-dd-MM')}>
                {format(new Date(review.date), 'MMMM, d, yyyy')}
              </time>
            </span>
          </p>
          {/* Content */}
          <div className="prose prose-sm mt-4 max-w-none text-gray-300">
            <p>{review.content}</p>
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-2 rounded-b-lg border-t-1 border-white border-opacity-[0.12] bg-[#19191C] p-4 md:px-6">
          {/* Profile Image */}
          <div className="flex items-center">
            <img
              src={review.author.imageUrl}
              alt={`${review.author.firstName} ${review.author.lastName}`}
              className="h-10 w-10 rounded-full border-white border-opacity-[0.12]"
            />
            <div className="ml-4 overflow-hidden">
              <h3 className="truncate text-sm font-medium text-cloud sm:text-base">
                {`${review.author.firstName} ${review.author.lastName}`}
              </h3>
              {review.author.title && (
                <h4 className="truncate text-xs font-medium text-darkText sm:text-sm">
                  {review.author.title}
                </h4>
              )}
            </div>
          </div>
          {/* Rating */}
          <div className="flex items-center">
            <StarIcon
              className="h-5 w-5 flex-shrink-0 text-brand-primary"
              aria-hidden="true"
            />
            <p className="ml-1 text-right font-bold text-cloud sm:text-lg">
              {review.rating}
              <span className="sr-only"> out of 5 stars</span>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
}

ReviewCard.propTypes = {
  review: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
