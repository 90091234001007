import { gql } from '@apollo/client';

export const SEARCH_SERVICES = gql`
  query ProviderSpecialtiesAutocomplete($query: String) {
    providerSpecialtiesAutocomplete(query: $query) {
      _id
      term
    }
  }
`;

export const PROVIDER_SERVICE_FUZZY_SEARCH = gql`
  query (
    $serviceQuery: String
    $locationQuery: String
    $filters: String
    $sortBy: String
    $offset: Int
  ) {
    providerServiceFuzzySearch(
      serviceQuery: $serviceQuery
      locationQuery: $locationQuery
      filters: $filters
      sortBy: $sortBy
      offset: $offset
    ) {
      _id
      username
      firstName
      lastName
      companyName
      phoneNumber
      bio
      specialty
      imageUrl
      reviewTotal
      reviewCount
      score
      location {
        address
        point {
          coordinates
        }
      }
      services {
        _id
        name
        description
        price
        duration
      }
    }
  }
`;
