import { ROLE } from './constants';

export const scrollToTop = () => {
  window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
};

const PROFILE_NOT_COMPLETED_MENU = [
  { name: 'Home', to: '/' },
  { name: 'Learn More', to: '/about' },
  { name: 'FAQs', to: '/faqs' },
];

const DEFAULT_MAIN_MENU = [
  ...PROFILE_NOT_COMPLETED_MENU,
  { name: 'BeautiMaps for Pros', to: '/signup?account=pro' },
];

const SEEKER_MAIN_MENU = [
  { name: 'Home', to: '/' },
  { name: 'Bookings', to: '/account/bookings' },
  { name: 'Reviews', to: '/account/reviews' },
];

const SEEKER_PROFILE_MENU = [
  {
    name: 'Your Profile',
    to: '/account/profile',
  },
  {
    name: 'Help',
    to: '/faqs',
  },
];

const PROVIDER_PROFILE_MENU = [
  {
    name: 'Your Profile',
    to: '/pd/personal',
  },
  {
    name: 'Your Brand',
    to: '/pd/business',
  },
  {
    name: 'Gallery',
    to: '/pd/gallery',
  },
  {
    name: 'Membership',
    to: '/pd/membership',
  },
  {
    name: 'Help',
    to: '/faqs',
  },
];

const PROVIDER_MAIN_MENU = [
  { name: 'Home', to: '/' },
  { name: 'Dashboard', to: '/pd/dashboard' },
  { name: 'Calendar', to: '/pd/bookings' },
  { name: 'Services', to: '/pd/services' },
  { name: 'Clients', to: '/pd/clients' },
  { name: 'Reviews', to: '/pd/reviews' },
];

const ADMIN_PROFILE_MENU = [
  {
    name: 'Help',
    to: '/faqs',
  },
];

const ADMIN_MAIN_MENU = [
  { name: 'Home', to: '/' },
  { name: 'Providers', to: '/ad/providers' },
  { name: 'Seekers', to: '/ad/seekers' },
];

export const getMainMenu = (isLoggedIn, accountType) => {
  if (isLoggedIn && !accountType) return PROFILE_NOT_COMPLETED_MENU;

  if (isLoggedIn && accountType === ROLE.Seeker) {
    return SEEKER_MAIN_MENU;
  }
  if (isLoggedIn && accountType === ROLE.Provider) {
    return PROVIDER_MAIN_MENU;
  }
  if (isLoggedIn && accountType === ROLE.Admin) {
    return ADMIN_MAIN_MENU;
  }

  return DEFAULT_MAIN_MENU;
};

export const getProfileMenu = (accountType) => {
  if (!accountType) return [];

  if (accountType === ROLE.Seeker) {
    return SEEKER_PROFILE_MENU;
  }
  if (accountType === ROLE.Provider) {
    return PROVIDER_PROFILE_MENU;
  }
  if (accountType === ROLE.Admin) {
    return ADMIN_PROFILE_MENU;
  }
  return [];
};

export const getRedirectTo = (accountType) => {
  if (accountType === ROLE.Admin) return '/ad';
  if (accountType === ROLE.Provider) return '/pd';
  return '/';
};
