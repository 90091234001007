import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import { GET_PROVIDER_AVAILABLILITY } from 'graphql/queries';
import TimeSlot from './TimeSlot';

export function TimeSlotList({
  inputDate,
  providerId,
  selectedIndex,
  serviceDuration,
  onSelectTimeSlot,
}) {
  const { loading, error, data } = useQuery(GET_PROVIDER_AVAILABLILITY, {
    variables: {
      date: format(inputDate, 'yyyy-MM-dd'),
      duration: serviceDuration,
      providerId,
    },
  });

  if (loading) {
    return (
      <div className="p-4 sm:px-6">
        <div className="mb-7 h-5 w-1/4 animate-pulse rounded bg-dark" />
        <ul className="flex gap-4 overflow-auto whitespace-nowrap px-1 py-3">
          {[1, 2, 3, 4, 5, 6].map((x) => (
            <li key={x}>
              <div className="h-12 animate-pulse" />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (error) {
    console.error(error.message);
    return (
      <div className="p-4 sm:px-6">
        We&#39;re sorry, we couldn&#39;t retrieve the availability of this
        service provider due to an error.
      </div>
    );
  }

  const handleTimeSlotClick = (availability, timeSlotIndex) => () => {
    onSelectTimeSlot(availability[timeSlotIndex], timeSlotIndex);
  };

  return (
    <div className="p-4 sm:px-6">
      <h3 className="text-sm font-medium text-cloud sm:text-base">
        Open Timeslots:
      </h3>
      {Array.isArray(data?.providerAvailability) &&
      data?.providerAvailability.length > 0 ? (
        <ul className="scrollbar flex gap-4 overflow-auto whitespace-nowrap px-1 py-3">
          {data.providerAvailability.map((timeSlot, index) => (
            <TimeSlot
              key={`${timeSlot.startTime}_${timeSlot.endTime}`}
              startTime={timeSlot.startTime}
              selected={index === selectedIndex}
              handleClick={handleTimeSlotClick(
                data.providerAvailability,
                index
              )}
            />
          ))}
        </ul>
      ) : (
        <div className="py-2">There are no openings on the selected date.</div>
      )}
    </div>
  );
}

TimeSlotList.propTypes = {
  inputDate: PropTypes.instanceOf(Date).isRequired,
  providerId: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  serviceDuration: PropTypes.number.isRequired,
  onSelectTimeSlot: PropTypes.func.isRequired,
};
