import { useApolloClient } from '@apollo/client';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useGetMe } from 'hooks/useGetMe';
import { useGetUserSession } from 'hooks/useGetUserSession';
import { logoutUser } from 'utils/auth';
import { getProfileMenu } from 'utils/navigation';
import { Dropdown, DropdownButton } from '../../Dropdown';
import { SkeletonAvatar } from '../../Skeleton';

export default function HeaderProfileMenu() {
  const navigate = useNavigate();
  const client = useApolloClient();

  const { user, accountType, loading, isLoggedIn } = useGetMe();
  const { userSession } = useGetUserSession();

  const handleLogout = useCallback(async () => {
    try {
      await logoutUser(client);

      console.log('Redirecting...');
      navigate('/login');
    } catch (error) {
      console.error('Unable to sign out. Please try again.');
    }
  }, [client]);

  const handleCompleteProfile = useCallback(() => {
    navigate('/signup/complete-profile', {
      state: {
        externalId: userSession.uid,
        externalEmail: userSession.email,
      },
    });
  }, []);

  const menu = useMemo(() => {
    if (isLoggedIn && !accountType) {
      return [
        {
          name: 'Complete Profile',
          action: handleCompleteProfile,
        },
        {
          name: 'Sign out',
          action: handleLogout,
        },
      ];
    }

    if (isLoggedIn) {
      return [
        ...getProfileMenu(accountType),
        {
          name: 'Sign out',
          action: handleLogout,
        },
      ];
    }
    return [];
  }, [isLoggedIn, accountType]);

  if (!isLoggedIn) {
    return (
      <>
        <Link to="/login" className="btn btn-link">
          Login
        </Link>
        <Link to="/signup?account=client" className="btn btn-accent ml-4">
          Get Started
        </Link>
      </>
    );
  }

  if (loading) {
    return <SkeletonAvatar className="h-8 w-8" />;
  }

  return (
    <Dropdown menu={menu}>
      <DropdownButton
        title="Open profile menu"
        className="flex items-center rounded-full text-sm"
      >
        <span className="sr-only">Open profile menu</span>
        {user?.imageUrl ? (
          <img
            className="h-8 w-8 rounded-full object-cover"
            src={user.imageUrl}
            alt="profile"
            referrerPolicy="no-referrer"
          />
        ) : (
          <SkeletonAvatar className="h-8 w-8" />
        )}

        <ChevronDownIcon
          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </DropdownButton>
    </Dropdown>
  );
}
