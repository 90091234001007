import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { SOCIALS } from 'utils/constants';

export default function Footer() {
  return (
    <footer className="bg-[#19191C] font-dm-sans antialiased">
      <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 md:order-3">
          {SOCIALS.map((social) => (
            <a
              key={social.name}
              href={social.ref}
              target="_blank"
              rel="noreferrer"
              className="group flex items-center text-sm font-medium text-cloud transition hover:text-brand-accent"
            >
              <social.icon
                aria-hidden="true"
                className="h-6 w-6 flex-none text-zinc-500 transition group-hover:text-brand-accent"
              />
              <span className="sr-only">Follow on {social.name}</span>
            </a>
          ))}
        </div>
        <div className="mt-8 flex justify-center gap-x-4 md:order-2 md:mt-0">
          <Link
            to="/privacy"
            className="text-center text-xs font-medium text-cloud text-opacity-60"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms"
            className="text-center text-xs font-medium text-cloud text-opacity-60"
          >
            Terms & Conditions
          </Link>
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs font-medium text-cloud text-opacity-60">
            &copy; {format(new Date(), 'yyyy')} BeautiMaps Technologies LLC. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
