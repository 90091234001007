import PropTypes from 'prop-types';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import {
  GET_ONBOARDING_STATUS,
  GET_ONBOARDING_LINK,
} from 'graphql/queries/provider';

export function OnboardingBanner({ className }) {
  const { data } = useQuery(GET_ONBOARDING_STATUS);
  const [
    getOnboardingLink,
    { data: accountLink, loading: loadingAccountLink },
  ] = useLazyQuery(GET_ONBOARDING_LINK, { fetchPolicy: 'no-cache' });

  if (accountLink && accountLink.stripeOnboardingLink) {
    window.location.assign(accountLink.stripeOnboardingLink);
  }
  if (
    !data ||
    !data.me ||
    (data.me.isStripeOnboardingComplete && data.me.verified)
  ) {
    return null;
  }

  const handleGetOnboardingLink = () => {
    getOnboardingLink();
  };

  return (
    <>
      {!data.me.isStripeOnboardingComplete && (
        <div className={className}>
          <button
            type="button"
            disabled={loadingAccountLink}
            onClick={handleGetOnboardingLink}
            className="flex w-full cursor-pointer rounded-md bg-[#E9C855] p-4 font-dm-sans text-[#28220A] antialiased transition-colors hover:bg-[#c4a843] md:justify-center"
          >
            <ExclamationCircleIcon
              className="mr-3 h-6 w-6 shrink-0"
              aria-hidden="true"
            />
            <p className="text-left text-sm font-semibold sm:text-base">
              Heads up, clients can&#39;t book you! Click here to complete the
              onboarding process.
            </p>
          </button>
        </div>
      )}
      {!data.me.verified && (
        <div className={className}>
          <div className="flex w-full rounded-md bg-[#E9C855] p-4 font-dm-sans text-[#28220A] antialiased transition-colors md:justify-center">
            <ExclamationCircleIcon
              className="mr-3 h-6 w-6 shrink-0"
              aria-hidden="true"
            />
            <p className="text-left text-sm font-semibold sm:text-base">
              Please be sure to verify your email address or clients won&#39;t
              be able to see your profile in the search results.
            </p>
          </div>
        </div>
      )}
    </>
  );
}

OnboardingBanner.propTypes = {
  className: PropTypes.string,
};

OnboardingBanner.defaultProps = {
  className: '',
};
