import { gql } from '@apollo/client';

export const GET_PROVIDERS = gql`
  query GetProviders($page: Int) {
    providers(page: $page) {
      totalProviders
      providers {
        __typename
        _id
        firstName
        lastName
        imageUrl
        email
        status
        phoneNumber
        companyName
        services {
          _id
          name
          price
          description
        }
      }
    }
  }
`;

export const GET_SEEKERS = gql`
  query GetSeekers($page: Int) {
    seekers(page: $page) {
      totalSeekers
      seekers {
        __typename
        _id
        firstName
        lastName
        imageUrl
        email
        phoneNumber
        status
      }
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($userId: ID!, $status: String!, $option: String!) {
    updateUserStatus(userId: $userId, status: $status, option: $option) {
      __typename
      ... on Provider {
        _id
        status
      }
      ... on Seeker {
        _id
        status
      }
    }
  }
`;
