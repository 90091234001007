// TODO Convert into form input and move to Form directory

/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { StarIcon } from '@heroicons/react/solid';

function ClickableStar({
  onClick,
  onMouseEnter,
  onMouseLeave,
  className = '',
  ...props
}) {
  return (
    <button
      type="button"
      className={`${className}`}
      onClick={onClick}
      data-value={props['data-value']}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StarIcon className="pointer-events-none" />
    </button>
  );
}

export function StarRating({ className = '', value, onChange, height, width }) {
  const max = 5;
  const initialRating = parseInt(value, 10) || 0;
  const [hoveredRating, setHoveredRating] = useState();

  useEffect(() => {
    setHoveredRating(value);
  }, [value]);

  const selectable = !!(value !== undefined && onChange !== undefined);
  const StarComponent = selectable ? ClickableStar : StarIcon;

  function handleClick(e) {
    const clickedIdx = e.target.attributes['data-value']?.value;
    onChange(clickedIdx);
  }

  function selectableProps(idx, selectable) {
    if (!selectable) {
      return {};
    }

    return {
      onClick: handleClick,
      'data-value': idx + 1,
      onMouseEnter: () => setHoveredRating(idx + 1),
      onMouseLeave: () => setHoveredRating(initialRating),
    };
  }

  const stars = [...Array(max).keys()].map((idx) => (
    <li key={idx}>
      <StarComponent
        className={`${height ? `h-${height}` : 'h-4'} ${
          width ? `w-${width}` : 'w-4'
        } ${
          idx < (hoveredRating || initialRating)
            ? 'text-brand-primary'
            : 'text-slate-400'
        } ${className}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectableProps(idx, selectable)}
      />
    </li>
  ));

  return <ul className="inline-flex">{stars}</ul>;
}
