import PropTypes from 'prop-types';

import { ITEMS_PER_PAGE } from 'utils/constants';

export function SkeletonGrid({ count }) {
  const emptyData = [];

  for (let i = 0; i < count; i += 1) {
    const item = (
      <li key={i} className="relative">
        <div className="aspect-w-10 aspect-h-7 group block h-[18.75rem] w-full overflow-hidden rounded-lg bg-skeleton" />
      </li>
    );
    emptyData.push(item);
  }

  return (
    <div className="mt-8 animate-pulse font-dm-sans antialiased lg:col-span-8 lg:col-start-5 lg:mt-0">
      <div className="flow-root">
        <ul
          role="status"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {emptyData}
        </ul>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

SkeletonGrid.propTypes = {
  count: PropTypes.number,
};

SkeletonGrid.defaultProps = {
  count: ITEMS_PER_PAGE,
};
