/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { useJsApiLoader } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { classNames } from 'utils/format';
import { googleMapsAPIConfig } from 'utils/google';
import PlacesAutocompleteResults from './PlacesAutocompleteResults';

const DEBOUNCED_INTERVAL_MS = 500;

export default function FormPlaces({ name, label, ...props }) {
  const [autocompleteService, setAutocompleteService] = useState();
  const {
    errors,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const [locationInput, setLocationInput] = useState(values[name].value || '');
  const [locationResults, setLocationResults] = useState();

  useEffect(() => {
    if (locationInput !== values[name].value) {
      setLocationInput(values[name].value);
    }
  }, [name, values]);

  const { isLoaded, loadError } = useJsApiLoader(googleMapsAPIConfig);

  useEffect(() => {
    if (isLoaded && !loadError) {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
    }
  }, [isLoaded, loadError]);

  const fetchLocationResults = (query) => {
    if (autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input: query,
          componentRestrictions: {
            country: 'us',
          },
          types: ['address'],
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setLocationResults(predictions);
          }
        }
      );
    }
  };

  const debouncedFetchLocationResults = debounce(
    fetchLocationResults,
    DEBOUNCED_INTERVAL_MS
  );

  const handleBlur = () => {
    setFieldTouched(name);
  };

  const handleChangeLocation = (e) => {
    const input = e.target.value;

    setFieldValue(name, { value: input });
    setLocationInput(input);

    if (input.length > 0) {
      debouncedFetchLocationResults(input);
    } else {
      setLocationResults(null);
    }
  };

  const handleSelect = (placeObj) => {
    const address = placeObj.description;
    setLocationInput(placeObj.description);
    setLocationResults(null);

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setFieldValue(name, {
          value: address,
          address,
          coordinates: latLng,
        });
        setLocationInput(address);
      })
      .catch((error) => setFieldError(name, error));
  };

  return (
    <div className="relative w-full">
      <div className="floating-input relative text-white">
        <input
          id={name}
          name={name}
          type="text"
          onBlur={handleBlur}
          placeholder={label}
          className={classNames(
            errors[`${name}.value`] && touched[`${name}.value`]
              ? 'border-[#CF6679]'
              : 'border-white border-opacity-[0.12]',
            'h-16 w-full rounded-md border  bg-transparent p-3 focus:border-gray-500 focus:shadow-sm focus:outline-none'
          )}
          value={locationInput}
          autoComplete="off"
          onChange={handleChangeLocation}
          {...props}
        />
        <label
          htmlFor={name}
          className="pointer-events-none absolute top-0 left-0 h-full origin-left transform px-3 py-5 transition-all duration-200 ease-in-out"
        >
          {label}
        </label>
        <ErrorMessage name={name}>
          {() => (
            <p
              className="mt-2 ml-2 text-sm text-[#e65e77]"
              id={`${name}.value-error`}
            >
              {errors[name].value || errors[name].address}
            </p>
          )}
        </ErrorMessage>
      </div>
      {locationResults && (
        <PlacesAutocompleteResults
          results={locationResults}
          onResultClick={(res) => handleSelect(res)}
          clearResults={() => setLocationResults(null)}
        />
      )}
    </div>
  );
}

FormPlaces.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
