import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  accessToken: '',
  isLoggedIn: false,
  accountType: '',
};

/* eslint-disable no-param-reassign */
const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.accessToken = action.payload;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.accessToken = '';
      state.accountType = '';
      state.isLoggedIn = false;
    },
    setAccountType(state, action) {
      state.accountType = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

export default store;
