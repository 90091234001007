import { signOut } from 'firebase/auth';
import { auth } from 'firebase-config';
import store, { authActions } from 'store';

export const loginUser = async (client, role, token) => {
  console.log('Inside the loginUser()', token);
  store.dispatch(authActions.setAccountType(role));
  store.dispatch(authActions.login(token));
  console.log('Redux state updated.');

  console.log({ client });
  if (client) {
    await client.resetStore();
    console.log('Apollo store reset.');
  }
};

export const logoutUser = async (client) => {
  await signOut(auth);
  console.log('User signed out sucessfully.');

  store.dispatch(authActions.logout());
  console.log('Redux state updated.');

  console.log({ client });
  if (client) {
    await client.clearStore();
    console.log('Apollo store cleared.');
  }
};
