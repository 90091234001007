/* eslint-disable react/jsx-props-no-spreading */

import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

export default function FormToggle({
  name,
  hiddenLabel,
  afterChange,
  beforeChange,
  ...props
}) {
  return (
    <div className="flex items-center">
      <Field type="checkbox" id={name} name={name} {...props}>
        {({ field: { value }, form: { setFieldValue } }) => (
          <Switch
            checked={value}
            onChange={(newVal) => {
              beforeChange(newVal);
              setFieldValue(name, newVal);
              afterChange(newVal);
            }}
            className={`${
              value ? 'bg-brand-primary' : 'bg-[#252123]'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">{hiddenLabel}</span>
            <span
              className={`${
                value ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        )}
      </Field>
    </div>
  );
}

FormToggle.propTypes = {
  name: PropTypes.string.isRequired,
  hiddenLabel: PropTypes.string,
  afterChange: PropTypes.func,
  beforeChange: PropTypes.func,
};

FormToggle.defaultProps = {
  hiddenLabel: 'Toggle option',
  afterChange: () => {},
  beforeChange: () => {},
};
