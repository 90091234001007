import { auth } from 'firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

export function useGetUserSession() {
  const [userSession] = useAuthState(auth);

  return {
    userSession,
  };
}
