import { Popover } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from 'assets/img/logos/logo_no_tagline.svg';

import { getRedirectTo } from 'utils/navigation';
import HeaderMainMenu from './HeaderMainMenu';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderProfileMenu from './HeaderProfileMenu';

export default function Header() {
  const { isLoggedIn, accountType } = useSelector((state) => state.auth);

  const redirectTo = useMemo(() => getRedirectTo(accountType), [accountType]);

  return (
    <Popover className="relative z-modal bg-[#19191C] font-dm-sans antialiased">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-3.5 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={redirectTo}>
              <span className="sr-only">BeautiMaps</span>
              <img className="mt-1 h-10 w-auto" src={logo} alt="BeautiMaps" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="cursor-pointer rounded p-1 text-sm font-semibold leading-[18/16rem] text-white text-opacity-80 transition-colors duration-200 ease-in-out hover:bg-[#212325] hover:text-opacity-100">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-7 w-7" aria-hidden="true" />
            </Popover.Button>
          </div>
          <HeaderMainMenu isLoggedIn={isLoggedIn} accountType={accountType} />
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <HeaderProfileMenu />
          </div>
        </div>
      </div>

      <HeaderMobileMenu />
    </Popover>
  );
}
