import PropTypes from 'prop-types';
import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';

const styles = {
  success: {
    bgColor: 'bg-[#66CF90]',
    textColor: 'text-[#183323]',
    icon: (iconstyles) => (
      <CheckCircleIcon className={`h-5 w-5 ${iconstyles}`} aria-hidden="true" />
    ),
  },
  error: {
    bgColor: 'bg-[#CF6679]',
    textColor: 'text-black',
    icon: (iconstyles) => (
      <XCircleIcon className={`h-5 w-5 ${iconstyles}`} aria-hidden="true" />
    ),
  },
  info: {
    bgColor: 'bg-[#60A5FA]',
    textColor: 'text-[#0B1827]',
    icon: (iconstyles) => (
      <InformationCircleIcon
        className={`h-5 w-5 ${iconstyles}`}
        aria-hidden="true"
      />
    ),
  },
  warning: {
    bgColor: 'bg-[#E9C855]',
    textColor: 'text-[#3A3213]',
    icon: (iconstyles) => (
      <ExclamationIcon className={`h-5 w-5 ${iconstyles}`} aria-hidden="true" />
    ),
  },
};

const defaultWrapperStyles = { margin: 'm-0' };

export function Alert({ status, message, children, alertWrapperStyle = {} }) {
  const alertStyle = Object.prototype.hasOwnProperty.call(styles, status)
    ? styles[status]
    : styles.info;

  const content = children || message;

  return (
    <div
      className={`mb-2 rounded-md p-4 ${alertStyle.bgColor} ${alertWrapperStyle.margin}`}
      role="alert"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {alertStyle.icon(alertStyle.textColor)}
        </div>
        <div className="ml-2">
          <p className={`text-sm font-medium ${alertStyle.textColor}`}>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  status: PropTypes.oneOf(Object.keys(styles)).isRequired,
  message: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  alertWrapperStyle: PropTypes.shape({
    margin: PropTypes.string,
  }),
};

Alert.defaultProps = {
  message: '',
  children: null,
  alertWrapperStyle: defaultWrapperStyles,
};
