import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { classNames } from 'utils/format';
import * as yup from 'yup';
import { Spinner } from '../Spinner';

export function MultiStepForm({
  steps,
  validationSchemas,
  initialValues,
  onSubmit,
  isLoading,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const lastStep = steps.length - 1;
  const validationSchema = validationSchemas[currentStep];
  const initialValuesMerged = initialValues.reduce((total, item) => ({
    ...total,
    ...item,
  }));

  const handlePrevious = () => {
    setCurrentStep((currStep) => currStep - 1);
  };

  const handleSubmit = (values, { setSubmitting, setFieldTouched }) => {
    if (currentStep === lastStep) {
      onSubmit(values);
    } else {
      setCurrentStep((currStep) => currStep + 1);
      Object.keys(initialValues[currentStep + 1]).forEach((key) => {
        setFieldTouched(key, false, false);
      });
    }

    setSubmitting(false);
  };

  const getText = () => (currentStep < lastStep ? 'Next' : 'Submit');

  return (
    <Formik
      initialValues={initialValuesMerged}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <Form className="space-y-6">
          {steps[currentStep]}

          <div className="mt-5 flex items-center gap-x-3">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="btn btn-block btn-outline"
              >
                Back
              </button>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className={classNames(
                currentStep < lastStep ? 'btn-white' : 'btn-accent',
                'btn btn-block flex justify-center'
              )}
            >
              {isLoading ? <Spinner /> : getText()}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

/* eslint-disable react/forbid-prop-types */
MultiStepForm.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.node).isRequired,
  validationSchemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
/* eslint-enable react/forbid-prop-types */
