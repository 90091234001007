import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

export const socials = [
  {
    name: 'Google',
    provider: GoogleAuthProvider,
    icon: (
      <svg
        className="float-left h-5 w-5"
        viewBox="0 0 14 14"
        aria-hidden="true"
        fill="currentColor"
      >
        <g clipPath="url(#a)" opacity={0.6}>
          <path
            d="M13.602 5.738c.082.47.122.946.122 1.423 0 2.13-.762 3.93-2.086 5.15h.001C10.481 13.38 8.89 14 7 14A7 7 0 0 1 7 0a6.728 6.728 0 0 1 4.683 1.822L9.685 3.82A3.804 3.804 0 0 0 7 2.77c-1.826 0-3.377 1.232-3.93 2.891a4.193 4.193 0 0 0 0 2.68h.002c.556 1.657 2.105 2.889 3.93 2.889.944 0 1.754-.242 2.383-.669h-.003a3.24 3.24 0 0 0 1.4-2.127H7V5.739h6.602z"
            fill="#FCFCFD"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h14v14H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: 'Facebook',
    provider: FacebookAuthProvider,
    icon: (
      <svg
        className="float-left h-5 w-5"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];
