import { gql } from '@apollo/client';

export const GET_SERVICES = gql`
  query GetServices($filter: ServicesFilter!, $limit: Int!, $page: Int!) {
    services(filter: $filter, limit: $limit, page: $page) {
      total
      results {
        _id
        name
        price
        deposit
        duration
        description
      }
    }
  }
`;
