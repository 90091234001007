import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import 'assets/css/tailwind.css';
import './firebase-config';
import { CurrentLocationProvider } from 'hooks/useCurrentLocation';
import API_BASE_PATH from 'utils/config';

import App from './App';
import store from './store';

// replace console.* to disable log in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

console.log('App loaded!');

const authLink = setContext((_, { headers }) => {
  const latestState = store.getState();
  const { accessToken } = latestState.auth;
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: `${API_BASE_PATH}/graphql`,
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          providerServiceFuzzySearch: offsetLimitPagination([
            'serviceQuery',
            'locationQuery',
            'filters',
            'sortBy',
          ]),
        },
      },
    },
  }),
  link: from([errorLink, authLink, httpLink]),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <NiceModal.Provider>
        <Router>
          <CurrentLocationProvider>
            <App />
          </CurrentLocationProvider>
        </Router>
      </NiceModal.Provider>
    </Provider>
  </ApolloProvider>
);
