import { useApolloClient } from '@apollo/client';
import { Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useGetMe } from 'hooks/useGetMe';
import { logoutUser } from 'utils/auth';
import { getMainMenu, getProfileMenu } from 'utils/navigation';
import MobileMenuSkeleton from './MobileMenuSkeleton';

export default function HeaderMobileMenu() {
  const client = useApolloClient();

  const { user, accountType, isLoggedIn } = useGetMe();

  const navigate = useNavigate();
  const navigation = useMemo(
    () => getMainMenu(isLoggedIn, accountType),
    [isLoggedIn, accountType]
  );

  const handleLogout = useCallback(async () => {
    try {
      await logoutUser(client);

      console.log('Redirecting...');
      navigate('/login');
    } catch (error) {
      console.error('Unable to sign out. Please try again.');
    }
  }, [client]);

  const menu = useMemo(() => {
    if (isLoggedIn) {
      return [
        ...getProfileMenu(accountType),
        {
          name: 'Sign out',
          action: handleLogout,
        },
      ];
    }
    return [];
  }, [isLoggedIn, accountType]);

  let profileContent = <MobileMenuSkeleton />;

  if (!isLoggedIn) {
    profileContent = (
      <div className="px-5">
        <Link
          to="/signup"
          className="btn btn-block btn-accent text-base shadow-sm"
        >
          Get Started
        </Link>
        <p className="mt-6 text-center text-base font-medium text-darkText">
          Already have an account?{' '}
          <Link to="/login" className="text-brand-accent hover:text-[#349396]">
            Log in
          </Link>
        </p>
      </div>
    );
  }

  if (user) {
    profileContent = (
      <>
        <div className="flex items-center px-5 sm:px-6">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full object-cover"
              src={user.imageUrl}
              alt="profile"
              referrerPolicy="no-referrer"
            />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium text-cloud">
              {`${user.firstName} ${user.lastName}`}
            </div>
            <div className="text-sm font-medium text-darkText">
              {user.email}
            </div>
          </div>
        </div>
        <div className="mt-3 space-y-1 px-2 sm:px-3">
          {menu.map((item) =>
            item.to ? (
              <Link
                key={item.name}
                to={item.to}
                className="block rounded-md px-3 py-2 text-base font-medium text-cloud text-opacity-80 hover:bg-dark"
              >
                {item.name}
              </Link>
            ) : (
              <button
                type="button"
                key={item.name}
                onClick={item.action}
                className="block w-full rounded-md px-3 py-2 text-left text-base font-medium hover:bg-dark"
              >
                {item.name}
              </button>
            )
          )}
        </div>
      </>
    );
  }

  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
      >
        <div className="divide-y-2 divide-white divide-opacity-[0.12] rounded-lg bg-[#22252A] shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="px-5 pt-2 pb-6">
            <div className="mt-3 space-y-3">
              <div className="flex justify-end">
                <Popover.Button className="cursor-pointer rounded p-1 text-sm font-semibold leading-[18/16rem] text-white text-opacity-80 transition-colors duration-200 ease-in-out hover:bg-[#212325] hover:text-opacity-100">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <nav className="grid w-full gap-y-8">
                {navigation.map((item) => (
                  <div key={item.name} className="w-full">
                    <Link
                      key={item.name}
                      to={item.to}
                      className="-m-3 flex items-center rounded-md p-2 text-cloud  hover:bg-dark"
                    >
                      <span className="ml-3 text-lg font-medium">
                        {item.name}
                      </span>
                    </Link>
                  </div>
                ))}
              </nav>
            </div>
          </div>
          <div className="space-y-6 py-6">{profileContent}</div>
        </div>
      </Popover.Panel>
    </Transition>
  );
}
