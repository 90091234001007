import PropTypes from 'prop-types';
import { classNames } from 'utils/format';

export const badgeStyles = {
  alert: {
    bgColor: 'bg-[#E4C9F9]',
    textColor: 'text-[#420259]',
    hoverColor: 'hover:bg-[#f5ecfc]',
  },
  error: {
    bgColor: 'bg-[#F9C9C9]',
    textColor: 'text-[#590202]',
    hoverColor: 'hover:bg-[#fcecec]',
  },
  success: {
    bgColor: 'bg-[#C9F9E2]',
    textColor: 'text-[#025942]',
    hoverColor: 'hover:bg-[#ecfcf5]',
  },
  warning: {
    bgColor: 'bg-yellow-100',
    textColor: 'text-[#3A3213]',
    hoverColor: 'hover:bg-yellow-200',
  },
  info: {
    bgColor: 'bg-blue-100',
    textColor: 'text-[#0B1827]',
    hoverColor: 'hover:bg-blue-200',
  },
};
export function Badge({ title, status, label, className }) {
  const alertStyle = Object.prototype.hasOwnProperty.call(badgeStyles, status)
    ? badgeStyles[status]
    : badgeStyles.info;

  return (
    <span
      title={title}
      className={classNames(
        'rounded-full px-2.5 py-0.5 text-xs font-medium md:px-3 md:text-sm',
        className,
        alertStyle.bgColor,
        alertStyle.textColor
      )}
    >
      {label}
    </span>
  );
}

// TODO: Update propType status to be oneOf([<booking statuses>])

Badge.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  title: 'status',
  className: '',
};
