import { useApolloClient } from '@apollo/client';
import {
  getAdditionalUserInfo,
  getIdTokenResult,
  getRedirectResult,
  onAuthStateChanged,
} from 'firebase/auth';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import GlobalErrorFallback from 'feature/GlobalErrorFallback';
import { auth } from 'firebase-config';
import { useRouter } from 'hooks';
import { loginUser } from 'utils/auth';
import { routePaths } from 'utils/constants';
import { scrollToTop } from 'utils/navigation';
import Routes from './Routes';
import Spinner from './components/@deprecated/Spinner/Spinner';
import { authActions } from './store';

function App() {
  console.log('test');
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { navigate, location } = useRouter();
  const [loading, isLoading] = useState(true);

  const signUpWithSocial = async (user) => {
    console.log('signupWithSocial', user);
    navigate('/signup/complete-profile', {
      state: {
        externalId: user.userId,
        externalEmail: user.email,
      },
    });
  };

  const signInWithSocial = async (idToken) => {
    if (idToken.claims.role) {
      await loginUser(client, idToken.claims.role, idToken.token);
      console.log('Successfully logged in user!');

      const { redirectTo } = queryString.parse(location.search);
      navigate(redirectTo || routePaths[idToken.claims.role]);
    } else {
      dispatch(authActions.setAccountType(null));
      dispatch(authActions.login(idToken.token));
      console.error('Unable to validate the role of this user');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Firebase User:', user);

      if (user) {
        try {
          const redirectCredential = await getRedirectResult(auth);

          // User logged in directly from an auth screen redirect
          if (redirectCredential) {
            const idToken = await getIdTokenResult(
              redirectCredential.user,
              true
            );
            const { isNewUser } = getAdditionalUserInfo(redirectCredential);
            if (isNewUser) {
              const userProfile = {
                userId: idToken.claims.sub,
                email:
                  (user.providerData && user.providerData[0]?.email) ?? null,
              };
              await signUpWithSocial(userProfile);
            } else {
              await signInWithSocial(idToken);
            }
          }
          // User logged in from a page refresh or indirectly from redirect
          else {
            const idToken = await getIdTokenResult(user, true);
            const type = idToken?.claims?.role || null;
            dispatch(authActions.setAccountType(type));
            dispatch(authActions.login(idToken.token));
            console.log(`User session refreshed successfully!`);
          }
        } catch (error) {
          console.error(error);
        }
      }
      isLoading(false);
    });
    return () => unsubscribe();
  }, [dispatch, navigate, location]);

  // Anytime page changes scroll to top
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  if (loading) return <Spinner />;

  return (
    <div className="flex min-h-screen flex-col scroll-smooth font-dm-sans text-cloud antialiased">
      <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
        <Toaster position="top-center" reverseOrder={false} />
        <Routes />
      </ErrorBoundary>
    </div>
  );
}

export default App;
