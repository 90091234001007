import { signInWithPopup } from 'firebase/auth';
import PropTypes from 'prop-types';

import { auth } from 'firebase-config';
import { socials } from './utils';

export function SocialButtons({ setErrorMessage }) {
  const handleSocialAuth = async (AuthProvider) => {
    try {
      setErrorMessage('');

      const provider = new AuthProvider();
      provider.addScope('email');
      provider.setCustomParameters({ prompt: 'select_account' });

      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error(error);
      setErrorMessage('Unable to log in. Please try again.');
    }
  };

  return (
    <div className="grid grid-cols-2 gap-3">
      {socials.map((social) => (
        <div key={social.name} className="col-span-1 md:col-span-3">
          <button
            type="button"
            onClick={() => handleSocialAuth(social.provider)}
            className="btn btn-outline inline-flex w-full justify-center py-3 px-5 text-cloud text-opacity-60 hover:text-opacity-100 sm:py-4 md:inline-block"
          >
            {social.icon}
            <span className="sr-only text-center font-medium md:not-sr-only">
              Continue with {social.name}
            </span>
          </button>
        </div>
      ))}
    </div>
  );
}

SocialButtons.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
};
