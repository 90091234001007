import PropTypes from 'prop-types';

import { classNames } from 'utils/format';
import Footer from './Footer';
import Header from './Header';

export function Container({ children, className }) {
  return (
    <>
      <Header />
      <main
        className={classNames('box mx-auto w-full flex-grow px-7', className)}
      >
        {children}
      </main>
      <Footer />
    </>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: '',
};
