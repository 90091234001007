import PropTypes from 'prop-types';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import { classNames } from 'utils/format';

export function FAQDropdown({ data }) {
  return (
    <dl className="mt-6 space-y-6 divide-y divide-white divide-opacity-[0.12] rounded-xl border border-white border-opacity-[0.12] bg-[#19191C]">
      {data.map((faq) => (
        <Disclosure as="div" key={faq.question} className="px-5 pt-6 last:pb-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between text-left text-darkText">
                  <span className="font-medium text-cloud">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? '-rotate-180' : 'rotate-0',
                        'h-6 w-6 transform transition-transform duration-200 ease-in-out'
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform opacity-0"
              >
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <p className="text-base text-darkText">{faq.answer}</p>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  );
}

FAQDropdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ),
};

FAQDropdown.defaultProps = {
  data: [],
};
