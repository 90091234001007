import { gql } from '@apollo/client';

export const TOKEN_STATUS = gql`
  query TokenStatus($userId: ID!, $token: String!, $tokenType: String!) {
    tokenStatus(userId: $userId, token: $token, tokenType: $tokenType)
  }
`;

export const GET_PROFILE = gql`
  query GetMe {
    me {
      __typename
      ... on Seeker {
        _id
        firstName
        lastName
        email
        imageUrl
      }
      ... on Provider {
        _id
        firstName
        lastName
        email
        imageUrl
      }
      ... on Admin {
        _id
        firstName
        lastName
        email
        imageUrl
      }
    }
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      __typename
      ... on Seeker {
        _id
        firstName
        lastName
        email
        phoneNumber
        imageUrl
        reviewTotal
        reviewCount
        stripeCustomerId
      }
      ... on Provider {
        _id
        username
        firstName
        lastName
        companyName
        website
        email
        phoneNumber
        imageUrl
        specialty
        cancellationPolicy
        bio
        location {
          address
        }
        verified
        isStripeOnboardingComplete
        availability
      }
    }
  }
`;
