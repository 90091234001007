/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';

import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormCheckboxGroup from './FormCheckboxGroup';
import FormPlaces from './FormPlaces';
import FormTextArea from './FormTextArea';
import FormStarRating from './FormStarRating';
import FormTimePicker from './FormTimePicker';
import FormToggle from './FormToggle';

export function FormControl({ control, ...props }) {
  switch (control) {
    case 'input':
      return <FormInput {...props} />;
    case 'select':
      return <FormSelect {...props} />;
    case 'checkbox-group':
      return <FormCheckboxGroup {...props} />;
    case 'places':
      return <FormPlaces {...props} />;
    case 'textarea':
      return <FormTextArea {...props} />;
    case 'star-rating':
      return <FormStarRating {...props} />;
    case 'time':
      return <FormTimePicker {...props} />;
    case 'toggle':
      return <FormToggle {...props} />;
    default:
      return null;
  }
}

FormControl.propTypes = {
  control: PropTypes.string.isRequired,
};
