import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query GetAppointments(
    $filter: AppointmentsFilter!
    $limit: Int!
    $page: Int!
  ) {
    appointments(filter: $filter, limit: $limit, page: $page) {
      total
      results {
        __typename
        ... on Event {
          _id
          summary
          status
          description
          createdAt
          type
          startTime
          endTime
        }
        ... on Appointment {
          _id
          payments {
            _id
            amount
          }
          subTotal
          tax
          total
          balanceRemaining
          seeker {
            _id
            firstName
            lastName
            email
            imageUrl
          }
          service {
            name
            duration
            price
          }
          provider {
            _id
            username
            firstName
            lastName
            imageUrl
            phoneNumber
            companyName
            location {
              address
            }
          }
          status
          startTime
          endTime
          clientFeedbackLeft
          providerFeedbackLeft
          createdAt
        }
      }
    }
  }
`;
