import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useClickAway } from 'react-use';

export function AutocompleteResults({ results, clearResults }) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    if (typeof clearResults === 'function') {
      clearResults(false);
    }
  });

  if (!results) {
    return null;
  }

  return (
    <ul className="absolute z-dropdown mt-1 w-full" ref={ref}>
      {results.map((result) => (
        <li
          key={result.id}
          className="cursor-pointer bg-raisin text-cloud first:rounded-t-md last:mb-0 last:rounded-b-md hover:bg-charcoal"
        >
          {result.element}
        </li>
      ))}
    </ul>
  );
}

AutocompleteResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      element: PropTypes.element.isRequired,
    })
  ).isRequired,
  clearResults: PropTypes.func,
};

AutocompleteResults.defaultProps = {
  clearResults: () => {},
};
