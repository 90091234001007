import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function NoData({ title, description, redirectLabel, redirectTo }) {
  return (
    <div className="mx-auto flex h-[75vh] w-full items-center justify-center sm:h-[70vh] md:h-[85vh]">
      <div className="max-w-xl text-center font-dm-sans antialiased">
        <h2 className="mb-5 text-xl font-bold text-white sm:text-3xl md:text-[2.625rem]">
          {title}
        </h2>
        <p className="mb-6 leading-[130%] text-white text-opacity-80 sm:text-lg">
          {description}
        </p>
        {redirectTo && redirectLabel && (
          <Link to={redirectTo} className="btn btn-primary">
            {redirectLabel}
          </Link>
        )}
      </div>
    </div>
  );
}

NoData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redirectLabel: PropTypes.string,
  redirectTo: PropTypes.string,
};

NoData.defaultProps = {
  redirectLabel: null,
  redirectTo: null,
};
