import { useLazyQuery } from '@apollo/client';
import { GET_PROFILE } from 'graphql/queries';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useGetMe() {
  const [getProfileInfo, { data, loading }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'cache-and-network',
  });

  const user = useMemo(() => data?.me, [data]);

  const { isLoggedIn, accountType } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      getProfileInfo();
      console.log('User profile fetched.');
    }
  }, [isLoggedIn]);

  return {
    user,
    loading,
    accountType,
    isLoggedIn,
  };
}
