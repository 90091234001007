import { useRef } from 'react';
import { useClickAway } from 'react-use';
import PropTypes from 'prop-types';

export default function PlacesAutocompleteResults({
  results,
  onResultClick,
  clearResults,
  accessor,
}) {
  const ref = useRef(null);

  useClickAway(ref, () => {
    clearResults(false);
  });

  if (!results) {
    return null;
  }

  return (
    <ul
      className="absolute z-dropdown mt-1 w-full divide-y divide-[#353537] rounded-md bg-[#26262B] shadow-xl ring-1 ring-white ring-opacity-5"
      ref={ref}
    >
      {results.map((res) => (
        <li
          key={res.place_id}
          className="cursor-pointer bg-inherit text-cloud transition-transform first:rounded-t-md last:rounded-b-md hover:bg-[#303036] focus:bg-[#303036]"
        >
          <button
            type="button"
            tabIndex="0"
            className="h-full w-full p-2 text-left"
            onClick={() => onResultClick(res)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onResultClick(res);
              }
            }}
          >
            {res[accessor]}
          </button>
        </li>
      ))}
    </ul>
  );
}

PlacesAutocompleteResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      place_id: PropTypes.string.isRequired,
    })
  ),
  onResultClick: PropTypes.func,
  clearResults: PropTypes.func,
  accessor: PropTypes.string,
};

PlacesAutocompleteResults.defaultProps = {
  results: [],
  onResultClick: () => {},
  clearResults: () => {},
  accessor: 'description',
};
