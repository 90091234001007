import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';

// Custom message component that can be used for nested errors
// default Error Message component can still be used for normal fields
export function FormErrorMessage({ name }) {
  return (
    <Field name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);
        // const touch = getIn(form.touched, name);
        return error ? (
          <p id={`${name}-error`} className="my-2 text-sm text-[#e65e77]">
            {error}
          </p>
        ) : null;
      }}
    </Field>
  );
}

FormErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};
