const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const libraries = ['places'];
const region = 'us';

export const googleMapsAPIConfig = {
  googleMapsApiKey: GOOGLE_API_KEY,
  libraries,
  region,
};
