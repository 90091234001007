/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import { Field, useFormikContext, ErrorMessage } from 'formik';

import { classNames } from 'utils/format';

export default function FormInput({ label, name, autoComplete, ...props }) {
  const { errors, touched } = useFormikContext();
  return (
    <div className="floating-input relative text-white">
      <Field
        id={name}
        name={name}
        className={classNames(
          errors[name] && touched[name]
            ? 'border-[#CF6679]'
            : 'border-white border-opacity-[0.12]',
          'h-16 w-full rounded-md border bg-transparent p-3 focus:border-gray-500 focus:shadow-sm focus:outline-none'
        )}
        placeholder={label}
        autoComplete={autoComplete}
        {...props}
      />
      <label
        htmlFor={name}
        className="pointer-events-none absolute top-0 left-0 h-full origin-left transform px-3 py-5 transition-all duration-200 ease-in-out"
      >
        {label}
      </label>
      <ErrorMessage name={name}>
        {(error) => (
          <p className="mt-2 ml-2 text-sm text-[#e65e77]" id={`${name}-error`}>
            {error}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
};

FormInput.defaultProps = {
  autoComplete: 'on',
};
