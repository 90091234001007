import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/format';

export default function ModalHeader({
  title,
  description,
  onClose,
  className,
}) {
  return (
    <header
      className={classNames(
        'min-h-[3.75rem] w-full bg-[#161618] px-4 pt-5 pb-4 sm:min-h-[4.25rem] sm:p-6',
        className
      )}
    >
      <div className="absolute top-1 right-1 pt-[0.625rem] pr-2 sm:pt-4 sm:pr-3">
        <button
          type="button"
          onClick={onClose}
          className="inline-flex items-center justify-center rounded-full border border-transparent bg-transparent bg-opacity-20 p-1 shadow-sm transition-colors ease-in-out hover:bg-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-cloud focus-visible:ring-opacity-75"
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Dialog.Title as="h3" className="font-bold leading-6 sm:text-lg">
        {title}
      </Dialog.Title>
      {description && (
        <Dialog.Description className="mt-1 max-w-[80%] text-sm sm:text-base">
          {description}
        </Dialog.Description>
      )}
    </header>
  );
}
ModalHeader.displayName = 'Header';

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ModalHeader.defaultProps = {
  description: '',
  className: '',
};
