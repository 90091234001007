import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { Menu, Transition } from '@headlessui/react';

export function SelectDropdown({ options, selectedOption, className }) {
  return (
    <Menu as="div" className={`relative ${className}`}>
      <Menu.Button
        type="button"
        className="flex min-w-[126px] items-center justify-between rounded-md border border-white border-opacity-[0.12] bg-transparent py-2 pl-3 pr-2 text-sm font-medium text-cloud shadow-sm transition-colors duration-200 ease-in-out hover:bg-gray-50 hover:bg-opacity-5"
      >
        {options.find(({ value }) => value === selectedOption).name}
        <ChevronDownIcon
          className="ml-2 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-[31] mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-[#26262B] shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option.name}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={option.action}
                    className={`${
                      active && 'bg-[#303036]'
                    } group flex w-full items-center rounded-md px-2 py-2 font-dm-sans text-sm font-medium text-white`}
                  >
                    {option.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

SelectDropdown.propTypes = {
  className: PropTypes.string,
  selectedOption: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    })
  ).isRequired,
};

SelectDropdown.defaultProps = {
  className: '',
};
