import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils/format';

export default function ModalContainer({ children, show, onClose, className }) {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-modal" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-cloud bg-opacity-10 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="-mt-4 flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 -translate-y-5"
              enterTo="opacity-100 transform-none"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-none"
              leaveTo="opacity-0 -translate-y-5"
            >
              {/*
               *  Using a div here instead of Dialog.Panel to disable the
               *  overly sensitive click-outside-to-close behavior
               */}
              <div
                className={classNames(
                  className || 'w-full sm:max-w-2xl md:max-w-3xl lg:max-w-4xl',
                  'relative transform overflow-hidden rounded-xl bg-[#19191C] text-left font-dm-sans text-cloud antialiased shadow-xl transition-all sm:my-8'
                )}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ModalContainer.defaultProps = {
  className: '',
};
