import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import { Container } from 'components';

const FORCE_REFRESH_STORAGE_KEY = 'bm-page-has-been-force-refreshed';

export default function GlobalErrorFallback({ error }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const [forceRefreshState, setForceRefreshState] = useSessionStorage(
    FORCE_REFRESH_STORAGE_KEY,
    false
  );
  const navigate = useNavigate();

  function handleReloadClick() {
    if (!forceRefreshState) {
      setForceRefreshState(true);
      window.location.reload();
    } else {
      setForceRefreshState(false);
      navigate('/');
      window.location.reload();
    }
  }

  return (
    <Container>
      <div className="mx-auto flex h-[75vh] w-full items-center justify-center sm:h-[70vh] md:h-[85vh]">
        <div className="max-w-xl text-center font-dm-sans antialiased">
          <h2 className="mb-5 text-xl font-bold text-white sm:text-3xl md:text-[2.625rem]">
            Uh oh, we hit a snag.
          </h2>
          <p className="mb-6 leading-[130%] text-white text-opacity-80 sm:text-lg">
            {forceRefreshState
              ? "Looks like we're still experiencing the same issue. Let's send you back home."
              : "We've run into an issue. Please refresh the page to try again."}
          </p>
          <button
            type="button"
            onClick={handleReloadClick}
            className="btn btn-white"
          >
            {forceRefreshState ? 'Take Me Home' : 'Refresh Page'}
          </button>
        </div>
      </div>
    </Container>
  );
}

GlobalErrorFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
};
