import PropTypes from 'prop-types';

import { classNames } from 'utils/format';

export default function ModalBody({ children, className }) {
  return (
    <div
      className={classNames(
        'scrollbar max-h-[25rem] overflow-y-auto overflow-x-hidden',
        className
      )}
    >
      {children}
    </div>
  );
}
ModalBody.displayName = 'Body';

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalBody.defaultProps = {
  className: '',
};
