import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from 'utils/format';
import { FormErrorMessage } from '../FormErrorMessage';

const convertTimeToAmPm = (timeString) => {
  let hours = parseInt(timeString.substring(0, 2), 10);
  const minutes = parseInt(timeString.substring(3, 5), 10);
  const amPM = hours >= 12 ? 'PM' : 'AM';
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }
  return {
    hours: hours.toString(),
    minutes: minutes.toString(),
    amPM,
  };
};

const convertAmPmToTime = (timeObject) => {
  let hours = parseInt(timeObject.hours, 10);
  const minutes = parseInt(timeObject.minutes, 10);
  if (timeObject.amPM === 'PM' && hours !== 12) {
    hours += 12;
  } else if (timeObject.amPM === 'AM' && hours === 12) {
    hours = 0;
  }
  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');
  return `${hoursString}:${minutesString}`;
};

const getHours = () => [...Array(12)].map((_, i) => `${i + 1}`);

const getMinutes = () =>
  [...Array(6)].map((_, i) => (i === 0 ? '00' : `${i * 10}`));

const getAmPM = () => ['AM', 'PM'];

export default function FormTimePicker({
  label,
  name,
  defaultTime,
  showError,
}) {
  const [time, setTime] = useState(convertTimeToAmPm(defaultTime));
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, convertAmPmToTime(time));
  }, [time]);

  return (
    <div className="relative">
      {label && (
        <label htmlFor={name} className="text-sm text-white text-opacity-75">
          {label}
        </label>
      )}
      <div className="flex h-full items-center rounded-md border border-white border-opacity-[0.12] p-1 text-sm font-medium tracking-wide text-cloud md:text-base">
        <TimePickerField
          name={name}
          value={time.hours}
          onChange={(hours) => {
            setTime({ ...time, hours });
          }}
          options={getHours()}
        />
        <div className="select-none">:</div>
        <TimePickerField
          name={name}
          value={time.minutes === '0' ? '00' : time.minutes}
          onChange={(minutes) => {
            setTime({ ...time, minutes });
          }}
          options={getMinutes()}
        />
        <TimePickerField
          name={name}
          value={time.amPM}
          onChange={(amPM) => {
            setTime({ ...time, amPM });
          }}
          options={getAmPM()}
        />
      </div>
      {showError && <FormErrorMessage name={name} />}
    </div>
  );
}

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultTime: PropTypes.string,
  showError: PropTypes.bool,
};

FormTimePicker.defaultProps = {
  label: '',
  defaultTime: '12:00',
  showError: true,
};

function TimePickerField({ name, value, onChange, options }) {
  return (
    <Field name={name}>
      {() => (
        <Listbox value={value} onChange={onChange}>
          {({ open }) => (
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default pl-2 pr-1.5">
                <span className="block truncate">{value}</span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Listbox.Options className="scrollbar absolute right-0 z-dropdown mt-1 max-h-36 w-auto min-w-[50px] origin-top-right divide-y divide-[#353537] overflow-auto rounded-md bg-[#26262B] py-1 text-center text-base shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(
                          active && 'bg-[#303036]',
                          'relative cursor-default select-none px-2 py-2 text-cloud'
                        )
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {option}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      )}
    </Field>
  );
}

TimePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};
