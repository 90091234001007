import { TbBrandInstagram, TbBrandMeta, TbBrandTwitter } from 'react-icons/tb';

import dashboardViews from '../assets/img/dashboard-views.png';
import businessTools from '../assets/img/business-tools.png';
import commissions from '../assets/img/commissions.png';
import makeup from '../assets/img/makeup.jpg';
import bridal from '../assets/img/bridal.jpg';
import lashes from '../assets/img/lashes.jpg';
import nails from '../assets/img/nails.jpg';
import facials from '../assets/img/facials.jpg';
import weaves from '../assets/img/weaves.jpg';
import braids from '../assets/img/braids.jpg';
import waxing from '../assets/img/waxing.jpg';

export const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const DEFAULT_TIMEZONE = 'America/New_York';

export const ITEMS_PER_PAGE = 6;

export const ServiceFilter = {
  PRICE_DESC: 'PRICE_DESC',
  PRICE_ASC: 'PRICE_ASC',
  NAME_DESC: 'NAME_DESC',
  NAME_ASC: 'NAME_ASC',
};

export const ReviewFilter = {
  GIVEN: 'GIVEN',
  RECEIVED: 'RECEIVED',
};

export const ROLE = {
  Provider: 'PROVIDER',
  Seeker: 'SEEKER',
  Admin: 'ADMIN',
};

export const SPECIALTY_OPTIONS = [
  { value: 'makeup', label: 'Makeup' },
  { value: 'lashes', label: 'Lashes' },
  { value: 'facials', label: 'Facials' },
  { value: 'waxing', label: 'Waxing' },
  { value: 'bridal', label: 'Bridal' },
  { value: 'nails', label: 'Nails' },
  { value: 'weaves and wigs', label: 'Weaves & Wigs' },
  { value: 'braids and locs', label: 'Braids & Locs' },
];

export const CATEGORIES = [
  {
    searchTerm: 'Eyelashes',
    name: 'Lashes & Brows',
    imageUrl: lashes,
  },
  {
    searchTerm: 'Makeup',
    name: 'Makeup',
    imageUrl: makeup,
  },
  {
    searchTerm: 'Bridal',
    name: 'Bridal',
    imageUrl: bridal,
  },
  {
    searchTerm: 'Skin+Treatments',
    name: 'Facials',
    imageUrl: facials,
  },
  {
    searchTerm: 'Nails',
    name: 'Nails',
    imageUrl: nails,
  },
  {
    searchTerm: 'Waxing',
    name: 'Waxing',
    imageUrl: waxing,
  },
  {
    searchTerm: 'Weaves and Wigs',
    name: 'Weaves & Wigs',
    imageUrl: weaves,
  },
  {
    searchTerm: 'Braids and Locs',
    name: 'Braids & Locs',
    imageUrl: braids,
  },
];

export const FEATURES = [
  {
    title: 'Easily, manage all your bookings',
    content:
      "With BeautiMaps simple yet, powerful suite of tools, you'll be able to manage your business and still have time for your family. The all-in-one beauty platform for clients and service providers.",
    imageUrl: dashboardViews,
    imageAlt: 'Dashboard views',
    imageFirst: false,
  },
  {
    title: 'Tools for Growth',
    content:
      'Our all-in-one platform provides you with everything you need to successfully manage your bookings. We are continuously listening to user feedback and improving the experience with new tools and features.',
    imageUrl: businessTools,
    imageAlt: 'Business tools',
    imageFirst: true,
  },
  {
    title: 'Competitive Rates',
    content:
      'BeautiMaps service providers are charged a 15% commision on each booking. For our Elite service providers, we charge commissions as low as 7%. We charge no monthly fees, interest rates, or even hidden fees. We keep our rates competitive so you can be in the driver seat of your business.',
    imageUrl: commissions,
    imageAlt: 'Commissions chart',
    imageFirst: false,
  },
];

export const routePaths = {
  ADMIN: '/ad',
  PROVIDER: '/pd',
  SEEKER: '/',
};

export const SOCIALS = [
  {
    name: 'Facebook',
    ref: 'https://www.facebook.com/BeautiMaps',
    icon: TbBrandMeta,
  },
  {
    name: 'Instagram',
    ref: 'https://www.instagram.com/beautimaps',
    icon: TbBrandInstagram,
  },
  {
    name: 'Twitter',
    ref: 'https://twitter.com/beautimaps',
    icon: TbBrandTwitter,
  },
];
