import { gql } from '@apollo/client';

export const GET_REVIEWS = gql`
  query GetReviews($filter: ReviewsFilter!, $limit: Int!, $page: Int!) {
    reviews(filter: $filter, limit: $limit, page: $page) {
      total
      results {
        _id
        rating
        title
        content
        createdAt
        appointment {
          service {
            name
          }
        }
        reviewer {
          __typename
          ... on Seeker {
            firstName
            lastName
            imageUrl
          }
          ... on Provider {
            firstName
            lastName
            imageUrl
            companyName
          }
        }
        reviewee {
          __typename
          ... on Seeker {
            firstName
            lastName
            imageUrl
          }
          ... on Provider {
            firstName
            lastName
            imageUrl
            companyName
          }
        }
      }
    }
  }
`;

export const GET_REVIEW_METRICS = gql`
  query GetReviewMetrics($filter: ReviewsFilter!) {
    reviewMetrics(filter: $filter) {
      _id
      average
      totalCount
      positive
      neutral
      negative
    }
  }
`;

export const GET_PROVIDER_REVIEWS = gql`
  query ProviderReviews($providerId: ID!, $limit: Int!, $page: Int!) {
    providerReviews(providerId: $providerId, limit: $limit, page: $page) {
      total
      results {
        _id
        rating
        title
        content
        createdAt
        appointment {
          service {
            name
          }
        }
        reviewer {
          __typename
          ... on Seeker {
            firstName
            lastName
            imageUrl
          }
        }
      }
    }
  }
`;
