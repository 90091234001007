import PropTypes from 'prop-types';

export function PageHeading({ title, children }) {
  return (
    <section
      className="mt-4 mb-3 flex flex-wrap items-center justify-between gap-3 font-dm-sans font-normal antialiased sm:my-8"
      aria-label={title.toLowerCase()}
    >
      <h1 className="text-xl font-bold sm:text-2xl md:mx-0 md:text-3xl lg:text-[2.63rem] lg:leading-[3.44rem]">
        {title}
      </h1>
      {children}
    </section>
  );
}

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

PageHeading.defaultProps = {
  children: undefined,
};
