import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/format';

export function DropdownButton({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Menu.Button {...props}>{children}</Menu.Button>
  );
}

export function Dropdown({
  menu,
  children,
  classes: { menuItems, menuItemParent, menuItemButton },
  options,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="max-w-[14rem] text-right" {...props}>
      <Menu as="div" className="relative inline-block text-left">
        <div className="h-full">{children}</div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              options.orientation === 'left'
                ? 'left-0 origin-top-left'
                : 'right-0 origin-top-right',
              'absolute z-dropdown mt-2 w-56 divide-y divide-[#353537] rounded-md bg-[#26262B] shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none',
              menuItems
            )}
          >
            <div className={`px-1 py-1 ${menuItemParent}`}>
              {menu.map((menuItem) => (
                <Menu.Item key={menuItem.name}>
                  {({ active }) =>
                    Object.prototype.hasOwnProperty.call(menuItem, 'to') ? (
                      <Link
                        to={menuItem.to}
                        className={`${
                          active && 'bg-[#303036]'
                        } flex w-full items-center rounded-md px-2 py-2 font-dm-sans text-sm font-medium text-white`}
                      >
                        {active ? menuItem.activeIcon : menuItem.inactiveIcon}
                        {menuItem.name}
                      </Link>
                    ) : (
                      <button
                        type="button"
                        onClick={menuItem.action}
                        className={`${
                          active && 'bg-[#303036]'
                        } flex w-full items-center rounded-md px-2 py-2 font-dm-sans text-sm font-medium text-white ${menuItemButton}`}
                      >
                        {active ? menuItem.activeIcon : menuItem.inactiveIcon}
                        {menuItem.name}
                      </button>
                    )
                  }
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    menuItems: PropTypes.string,
    menuItemParent: PropTypes.string,
    menuItemButton: PropTypes.string,
  }),
  options: PropTypes.shape({
    orientation: PropTypes.oneOf(['left', 'right']),
  }),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      activeIcon: PropTypes.node,
      inactiveIcon: PropTypes.node,
      to: PropTypes.string,
      action: PropTypes.func,
    })
  ).isRequired,
};

Dropdown.defaultProps = {
  className: '',
  options: {
    orientation: 'right',
  },
  classes: {
    menuItems: '',
    menuItemParent: '',
    menuItemButton: '',
  },
};
DropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
};
