import PropTypes from 'prop-types';

import { ITEMS_PER_PAGE } from 'utils/constants';
import { SkeletonBar } from './SkeletonBar';

export function SkeletonList({ count }) {
  const emptyData = [];

  for (let i = 0; i < count; i += 1) {
    const item = (
      <li key={i}>
        <div className="w-full rounded-xl border border-white border-opacity-[0.12] bg-[#19191C] text-cloud shadow">
          <div className="w-full py-4 px-4 sm:py-6 sm:px-6 md:py-8 md:px-8">
            <SkeletonBar className="mb-4 w-48" />
            <SkeletonBar className="mb-2.5 max-w-[480px]" />
            <SkeletonBar className="mb-2.5" />
            <SkeletonBar className="mb-2.5 max-w-[440px]" />
            <SkeletonBar className="mb-2.5 max-w-[460px]" />
            <SkeletonBar className="max-w-[360px]" />
          </div>
        </div>
      </li>
    );
    emptyData.push(item);
  }

  return (
    <div className="mt-8 animate-pulse font-dm-sans antialiased lg:col-span-8 lg:col-start-5 lg:mt-0">
      <div className="flow-root">
        <ul role="status" className="flex flex-col space-y-3 ">
          {emptyData}
        </ul>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

SkeletonList.propTypes = {
  count: PropTypes.number,
};

SkeletonList.defaultProps = {
  count: ITEMS_PER_PAGE,
};
