import HCaptcha from '@hcaptcha/react-hcaptcha';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const Captcha = forwardRef(
  ({ onVerify, size = 'normal', ...rest }, ref) =>
    process.env.NODE_ENV === 'production' ? (
      <HCaptcha
        ref={ref}
        sitekey={SITE_KEY}
        theme="dark"
        onVerify={onVerify}
        size={size}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    ) : null
);

const captchaSizes = {
  normal: 'normal',
  compact: 'compact',
  invisible: 'invisible',
};

Captcha.propTypes = {
  size: PropTypes.oneOf(Object.keys(captchaSizes)),
  onVerify: PropTypes.func.isRequired,
};

Captcha.defaultProps = {
  size: 'normal',
};
