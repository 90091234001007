import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { useMemo } from 'react';
import { ROLE } from 'utils/constants';
import { classNames } from 'utils/format';
import { getMainMenu } from 'utils/navigation';

export default function HeaderMainMenu({ isLoggedIn, accountType }) {
  const navigation = useMemo(
    () => getMainMenu(isLoggedIn, accountType),
    [isLoggedIn, accountType]
  );

  return (
    <nav className="hidden space-x-10 md:flex" aria-label="Main Menu">
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.to}
          className={({ isActive }) =>
            classNames(
              isActive ? 'text-opacity-100' : 'text-opacity-60',
              'font-dm-sans font-medium tracking-wide text-white  transition-colors duration-200 ease-in-out hover:text-opacity-100'
            )
          }
        >
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
}

HeaderMainMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accountType: PropTypes.oneOf([...Object.values(ROLE), '']),
};

HeaderMainMenu.defaultProps = {
  accountType: null,
};
