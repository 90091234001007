import { gql } from '@apollo/client';

export const GET_PROVIDER_AVAILABLILITY = gql`
  query ProviderAvailability(
    $providerId: ID!
    $date: String!
    $duration: Int!
  ) {
    providerAvailability(
      providerId: $providerId
      date: $date
      duration: $duration
    ) {
      startTime
      endTime
    }
  }
`;

export const GET_PROVIDER = gql`
  query GetProvider($providerId: String!) {
    provider(providerId: $providerId) {
      _id
      username
      firstName
      lastName
      companyName
      cancellationPolicy
      website
      phoneNumber
      bio
      specialty
      photos {
        _id
        ref
        width
        height
        description
      }
      imageUrl
      reviewTotal
      reviewCount
      location {
        address
        point {
          coordinates
        }
      }
      services {
        _id
        name
        description
        price
        deposit
        duration
      }
    }
  }
`;

export const GET_AVAILABLILITY = gql`
  query GetMe {
    me {
      __typename
      ... on Provider {
        _id
        availability
      }
    }
  }
`;

export const GET_MEMBERSHIP = gql`
  query GetMe {
    me {
      __typename
      ... on Provider {
        _id
        membership {
          status
          description
          price
          interval
          nextInvoice
        }
      }
    }
  }
`;

export const GET_ONBOARDING_STATUS = gql`
  query GetMe {
    me {
      __typename
      ... on Provider {
        _id
        isStripeOnboardingComplete
        verified
      }
    }
  }
`;

export const GET_ONBOARDING_LINK = gql`
  query {
    stripeOnboardingLink
  }
`;

export const GET_STRIPE_LOGIN_LINK = gql`
  mutation {
    stripeLoginLink
  }
`;

export const GET_DASHBOARD_STATS = gql`
  query DashboardStats($startDate: String!, $endDate: String!) {
    dashboardStats(startDate: $startDate, endDate: $endDate) {
      remaining
      completed
    }
  }
`;

export const CREATE_PORTAL_SESSION = gql`
  query {
    stripeCustomerPortalSession
  }
`;

export const GET_CLIENTS = gql`
  query GetClients($limit: Int!, $page: Int!) {
    clients(limit: $limit, page: $page) {
      total
      results {
        firstName
        lastName
        email
        phoneNumber
        imageUrl
        totalBookings
        lifetimeSpend
      }
    }
  }
`;

export const EXPORT_CLIENTS = gql`
  query ExportClients {
    exportClients
  }
`;

export const GET_GALLERY_IMAGES = gql`
  query GetMe {
    me {
      __typename
      ... on Provider {
        _id
        username
        photos {
          _id
          ref
          height
          width
          description
        }
      }
    }
  }
`;

export const GET_BOOKING_PERFORMANCE_METRICS = gql`
  query BookingPerformance($startDate: String!, $endDate: String!) {
    bookingPerformance(startDate: $startDate, endDate: $endDate) {
      date
      value
    }
  }
`;

export const GET_TOP_BOOKINGS = gql`
  query TopBookings($startDate: String!, $endDate: String!) {
    topBookings(startDate: $startDate, endDate: $endDate) {
      _id
      seeker {
        firstName
        lastName
        imageUrl
      }
      service {
        name
      }
      startTime
    }
  }
`;

export const GET_MONTHLY_EARNINGS = gql`
  query Earnings($startDate: String!, $endDate: String!) {
    earnings(startDate: $startDate, endDate: $endDate) {
      thisMonth
      lastMonth
    }
  }
`;

export const GET_TOP_SERVICES = gql`
  query TopServices {
    topServices {
      _id
      name
    }
  }
`;

export const GET_MONTHLY_CANCELLATION = gql`
  query MonthlyCancellation($startDate: String!, $endDate: String!) {
    monthlyCancellation(startDate: $startDate, endDate: $endDate) {
      rating
      statuses {
        status
        count
        percent
      }
    }
  }
`;
