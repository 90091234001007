import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { googleMapsAPIConfig } from 'utils/google';

const CurrentLocationContext = createContext();

export function CurrentLocationProvider({ children }) {
  const [geocoder, setGeocoder] = useState();
  const [currentLatLng, setCurrentLatLng] = useState();
  const [loading, setLoading] = useState();
  const [city, setCity] = useState();

  const callbackRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader(googleMapsAPIConfig);

  function getLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by this browser'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            resolve(coords);
          },
          () => {
            reject(new Error('Unable to retrieve your location'));
          }
        );
      }
    });
  }

  const getCityFromLatLng = useCallback(
    (latLng) =>
      new Promise((resolve, reject) => {
        const [lat, lng] = latLng;

        geocoder
          .geocode({
            location: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          })
          .then((response) => {
            if (response.results[0]) {
              const { address_components: addressComponents } =
                response.results[0];
              const city = addressComponents.find((addr) =>
                addr.types.includes('locality')
              );
              const state = addressComponents.find((addr) =>
                addr.types.includes('administrative_area_level_1')
              );
              const locationString = `${city.long_name}, ${state.short_name}`;
              resolve(locationString);
            } else {
              reject(new Error("Couldn't find current city"));
            }
          })
          .catch((err) => reject(new Error(`Geocoder failed due to: ${err}`)));
      }),
    [geocoder]
  );

  const updateCurrentLocation = (cb) => {
    setLoading(true);
    setCurrentLatLng(null);
    setCity(null);
    getLocation()
      .then((coords) => {
        setCurrentLatLng(coords);
        callbackRef.current = cb;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearCity = () => {
    setCity(null);
  };

  useEffect(() => {
    let isMounted = true;

    if (!city) {
      setLoading(true);
      getLocation()
        .then((coords) => {
          if (isMounted && !city) {
            setCurrentLatLng(coords);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      isMounted = false;
      return isMounted;
    };
  }, [city]);

  useEffect(() => {
    let isMounted = true;

    if (currentLatLng && geocoder) {
      getCityFromLatLng(currentLatLng)
        .then((locationString) => {
          if (isMounted) {
            setCity(locationString);
            if (typeof callbackRef.current === 'function') {
              callbackRef.current(locationString);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
      return isMounted;
    };
  }, [currentLatLng, geocoder, getCityFromLatLng]);

  useEffect(() => {
    if (isLoaded && !loadError) {
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, [isLoaded, loadError]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    loading,
    currentLatLng,
    city,
    updateCurrentLocation,
    clearCity,
  };

  return (
    <CurrentLocationContext.Provider value={value}>
      {children}
    </CurrentLocationContext.Provider>
  );
}

CurrentLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useCurrentLocation() {
  const context = useContext(CurrentLocationContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentLocation must be used within a CurrentLocationProvider'
    );
  }
  return context;
}
