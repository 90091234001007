import PropTypes from 'prop-types';
import { Suspense, lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes as Router } from 'react-router-dom';

import { useRouter } from 'hooks';
import { useGetUserSession } from 'hooks/useGetUserSession';
import { useGetMe } from 'hooks/useGetMe';
import { ROLE } from 'utils/constants';
import { getRedirectTo } from 'utils/navigation';
import Spinner from './components/@deprecated/Spinner/Spinner';

const Login = lazy(() => import('feature/Login'));
const Signup = lazy(() => import('feature/Signup'));
const ForgotPassword = lazy(() => import('feature/ForgotPassword'));
const SignupConfirmation = lazy(() =>
  import('feature/Signup/SignupConfirmation')
);
const CompleteSignup = lazy(() => import('feature/Signup/CompleteSignup'));
const Dashboard = lazy(() => import('feature/Dashboard'));
const Clients = lazy(() => import('feature/Clients'));
const Services = lazy(() => import('feature/Services'));
const Account = lazy(() => import('feature/Account'));
const Business = lazy(() => import('feature/Business'));
const Membership = lazy(() => import('feature/Membership'));
const Gallery = lazy(() => import('feature/Gallery'));
const Reviews = lazy(() => import('feature/Reviews'));
const Privacy = lazy(() => import('feature/Privacy'));
const Terms = lazy(() => import('feature/Terms'));
const About = lazy(() => import('feature/About'));
const NotFound = lazy(() => import('feature/NotFound'));
const Contact = lazy(() => import('feature/Contact'));
const FAQs = lazy(() => import('feature/FAQs'));
const ConfirmEmail = lazy(() => import('feature/ConfirmEmail'));
const VerifyEmail = lazy(() => import('feature/VerifyEmail'));
const BookingConfirmation = lazy(() => import('feature/BookingConfirmation'));
const ProvidersDetails = lazy(() => import('feature/ProviderDetails'));
const Search = lazy(() => import('feature/Search'));
const Booking = lazy(() => import('feature/Booking'));
const DashboardProviders = lazy(() =>
  import('feature/Dashboard/Admin/DashboardProviders')
);
const DashboardSeekers = lazy(() =>
  import('feature/Dashboard/Admin/DashboardSeekers')
);

const Landing = lazy(() => import('feature/Landing'));

function ProtectedRoute({ roles, children }) {
  const { location } = useRouter();
  const { isLoggedIn, accountType } = useGetMe();
  const { userSession } = useGetUserSession();

  const userHasRequiredRole = accountType && roles?.includes(accountType);

  if (!isLoggedIn) {
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  // if the user is logged in but has not completed their profile (accountType is null)
  // then redirect them to the complete profile page
  if (!accountType && userSession) {
    return (
      <Navigate
        to="/signup/complete-profile"
        replace
        state={{
          externalId: userSession.uid,
          externalEmail: userSession.email,
        }}
      />
    );
  }

  if (isLoggedIn && !userHasRequiredRole) {
    return <Navigate to="/" />;
  }

  return children || <Outlet />;
}

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

ProtectedRoute.defaultProps = {
  roles: [],
  children: undefined,
};

export default function Routes() {
  const { isLoggedIn, accountType } = useSelector((state) => state.auth);

  const redirectTo = useMemo(getRedirectTo, [accountType]);

  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        {/* Public Routes */}
        <Route index element={<Landing />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="faqs" element={<FAQs />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="search" element={<Search />} />
        <Route path="p">
          <Route path=":providerId" element={<ProvidersDetails />} />
        </Route>
        <Route path="login">
          <Route
            index
            element={
              !isLoggedIn ? <Login /> : <Navigate to={redirectTo} replace />
            }
          />
          <Route path="verify-email" element={<VerifyEmail />} />
        </Route>
        <Route path="signup">
          <Route
            index
            element={
              !isLoggedIn ? <Signup /> : <Navigate to={redirectTo} replace />
            }
          />
          <Route
            path="success"
            element={
              !isLoggedIn ? (
                <SignupConfirmation />
              ) : (
                <Navigate to={redirectTo} replace />
              )
            }
          />
          <Route path="complete-profile" element={<CompleteSignup />} />
        </Route>
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="verify">
          <Route path=":userId">
            <Route path=":token" element={<ConfirmEmail />} />
          </Route>
        </Route>
        {/* Seeker Routes */}
        <Route
          path="account"
          element={<ProtectedRoute roles={[ROLE.Seeker]} />}
        >
          <Route index element={<Account />} />
          <Route path="profile" element={<Account />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="reviews" element={<Reviews />} />
        </Route>
        <Route
          path="booking-success"
          element={<ProtectedRoute roles={[ROLE.Seeker]} />}
        >
          <Route index element={<BookingConfirmation />} />
        </Route>
        {/* Provider Routes */}
        <Route path="pd" element={<ProtectedRoute roles={[ROLE.Provider]} />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="clients" element={<Clients />} />
          <Route path="services" element={<Services />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="personal" element={<Account />} />
          <Route path="business" element={<Business />} />
          <Route path="membership" element={<Membership />} />
          <Route path="gallery" element={<Gallery />} />
        </Route>
        {/* Admin Routes */}
        <Route path="ad" element={<ProtectedRoute roles={[ROLE.Admin]} />}>
          <Route index element={<DashboardProviders />} />
          <Route path="seekers" element={<DashboardSeekers />} />
          <Route path="providers" element={<DashboardProviders />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Router>
    </Suspense>
  );
}
