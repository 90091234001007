import { SkeletonAvatar, SkeletonBar } from 'components/Skeleton';

export default function MobileMenuSkeleton() {
  const emptyData = [1, 2, 3];

  return (
    <div className="animate-pulse px-5 sm:px-6">
      <div className="flex items-center space-y-2">
        <SkeletonAvatar className="h-10 w-10" />
        <div className="ml-3 flex-1 space-y-2">
          <SkeletonBar className="w-3/4" />
          <SkeletonBar className="w-2/4" />
        </div>
      </div>
      <div className="mt-5 space-y-4 px-2 sm:px-3">
        {emptyData.map((el) => (
          <SkeletonBar key={el} />
        ))}
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
