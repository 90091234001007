export function SkeletonCard() {
  return (
    <div className="space-y-5 rounded-xl border-1 border-white border-opacity-[0.12] bg-[#19191C] p-4 shadow-lg">
      <div className="h-4 animate-pulse rounded-lg bg-cloud/10" />
      <div className="space-y-3">
        <div className="h-3 w-3/5 animate-pulse rounded-lg bg-cloud/10" />
        <div className="h-3 w-4/5 animate-pulse rounded-lg bg-cloud/20" />
        <div className="h-3 w-2/5 animate-pulse rounded-lg bg-cloud/20" />
      </div>
    </div>
  );
}
