import PropTypes from 'prop-types';

import { ITEMS_PER_PAGE } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export function Pagination({ total, page, limit, setPage, scrollToRef }) {
  const lastPageItems = total % limit;
  const currentPageItems = page <= total / limit ? limit : lastPageItems;
  const currentPageStart = (page - 1) * limit + 1;
  const currentPageEnd = (page - 1) * limit + currentPageItems;
  const lastPage = Math.ceil(total / limit);

  const scrollPage = (ref) => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  };

  return (
    <nav
      className="mt-6 flex items-center justify-between font-dm-sans antialiased"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-base text-cloud">
          Showing <span className="font-medium">{currentPageStart}</span> to{' '}
          <span className="font-medium">{currentPageEnd}</span> of{' '}
          <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between space-x-3 sm:justify-end">
        {page > 1 && (
          <button
            type="button"
            disabled={page <= 1}
            onClick={() => {
              // eslint-disable-next-line no-return-assign, no-param-reassign
              setPage((prevState) => (prevState -= 1));
              scrollPage(scrollToRef);
            }}
            className="cursor-pointer rounded border border-white border-opacity-[0.12] p-3 text-sm font-medium leading-[18/16rem] text-white  transition-colors duration-200 ease-in-out hover:bg-[#212325]"
          >
            Previous
          </button>
        )}
        {page < lastPage && (
          <button
            type="button"
            disabled={false}
            onClick={() => {
              // eslint-disable-next-line no-return-assign, no-param-reassign
              setPage((prevState) => (prevState += 1));
              scrollPage(scrollToRef);
            }}
            className="cursor-pointer rounded border border-white border-opacity-[0.12] p-3 text-sm font-medium leading-[18/16rem] text-white  transition-colors duration-200 ease-in-out hover:bg-[#212325]"
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
}

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  setPage: PropTypes.func,
  scrollToRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Pagination.defaultProps = {
  total: 0,
  page: 1,
  limit: ITEMS_PER_PAGE,
  setPage: null,
  scrollToRef: null,
};
