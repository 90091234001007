import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { classNames } from 'utils/format';

export default function TimeSlot({ selected, startTime, handleClick }) {
  const formattedTime = format(new Date(startTime), 'h:mmaaa');

  return (
    <li title={formattedTime} className="inline-block text-center">
      <button
        type="button"
        onClick={handleClick}
        className={classNames(selected ? 'btn-primary' : 'btn-outline', 'btn')}
      >
        {formattedTime}
      </button>
    </li>
  );
}

TimeSlot.propTypes = {
  selected: PropTypes.bool.isRequired,
  startTime: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
