import PropTypes from 'prop-types';
import React from 'react';

import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalContainer from './ModalContainer';

export function Modal({ show, children, onClose, className }) {
  const header = React.Children.map(children, (child) =>
    child.type.displayName === 'Header' ? child : null
  );
  const body = React.Children.map(children, (child) =>
    child.type.displayName === 'Body' ? child : null
  );
  const footer = React.Children.map(children, (child) =>
    child.type.displayName === 'Footer' ? child : null
  );

  return (
    <ModalContainer show={show} onClose={onClose} className={className}>
      {header}
      {body}
      {footer}
    </ModalContainer>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
