import { format, parseISO } from 'date-fns';

export function obscureEmail(email) {
  if (!email) return '';
  const [name, domain] = email.split('@');
  return `${name[0]}${new Array(name.length - 1).join('*')}${
    name[name.length - 1]
  }@${domain}`;
}

export function toCapitalCase(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ').trim();
}

export function formatPercent(num, digits = 2) {
  return `${parseFloat(num * 100).toFixed(digits)}%`;
}

export function formatCurrency(num) {
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return usdFormatter.format(num);
}

export function formatFilesize(size) {
  let formattedSize = '';
  if (size > 1024) {
    if (size > 1048576) {
      formattedSize = `${Math.round(size / 1048576)} MB`;
    } else {
      formattedSize = `${Math.round(size / 1024)} KB`;
    }
  } else {
    formattedSize = `${size} B`;
  }
  return formattedSize;
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export const formatDurationDescription = ({ hours, minutes }) => {
  const hoursDescription =
    hours > 0 ? `${hours} ${hours !== 1 ? 'hrs' : 'hr'}` : '';
  const minutesDescription =
    minutes > 0 ? `${minutes} ${minutes !== 1 ? 'mins' : 'min'}` : '';

  return `${hoursDescription}${
    hours > 0 && minutes > 0 ? ', ' : ''
  }${minutesDescription}`;
};

export const formatDate = (date, strFormat) =>
  format(parseISO(date), strFormat);
