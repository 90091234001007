import PropTypes from 'prop-types';
import { classNames } from 'utils/format';

export function SkeletonBar({ className }) {
  return (
    <div
      className={classNames('max-w-36 h-3 rounded-full bg-skeleton', className)}
    />
  );
}

SkeletonBar.propTypes = {
  className: PropTypes.string,
};

SkeletonBar.defaultProps = {
  className: '',
};
